<template>
    <div v-if="isShow" class="modal" :class="[`modal--${$options.name}`]">
        <div class="modal__close btn btn--remove" @click="close"></div>

        <div class="modal__title">
            <template v-if="isTakeaway">
                <template v-if="timeType === 'soon'">
                    <template v-if="finishCountMinutes > 90">
                        Ваш заказ #{{ orderId }}, с вами свяжется оператор для подтверждения заказа
                    </template>
                    <template v-else>
                        Самовывоз #{{ orderId }}
                        на сумму {{ paymentTotal }} руб.
                        будет готов {{ finishDate }}
                        до {{ finishTime }}
                        по адресу г.{{ cityName }}, {{ shopAddress }}
                    </template>
                </template>

                <template v-else>
                    Самовывоз #{{ orderId }}
                    на сумму {{ paymentTotal }} руб.
                    будет готов {{ finishDate }} К {{ payloadTime }}
                    по адресу г.{{ cityName }}, {{ shopAddress }}
                </template>
            </template>

            <template v-else>
                <template v-if="timeType === 'soon'">
                    <template v-if="finishCountMinutes > 120">
                        Ваш заказ №{{ orderId }}, с вами свяжется оператор для подтверждения заказа
                    </template>
                    <template v-else>
                        Заказ #{{ orderId }}
                        на сумму {{ paymentTotal }}
                        руб. будет доставлен {{ finishDate }}
                        до {{ finishTime }}.
                        По адресу г. {{ cityName }}, {{ deliveryAddress }}
                    </template>
                </template>

                <template v-else>
                    Заказ #{{ orderId }}
                    на сумму {{ paymentTotal }} руб.
                    будет доставлен {{ finishDate }}
                    К {{ payloadTime }}
                    по адресу г.{{ cityName }}, {{ deliveryAddress }}
                </template>
            </template>
        </div>

        <div class="btn btn--red" @click="close">Хорошо</div>
    </div>
</template>

<script>
export default {
    name: 'order-created',

    methods: {
        open() {
            this.$root.openModal(this.$options.name);
        },

        close() {
            window.location.pathname = '/';
        },

        formatDate(dateString) {
            return window.moment(dateString).format('DD.MM.YYYY')
        },
    },

    computed: {
        isShow() {
            return this.$root.modal.open.name === this.$options.name;
        },

        orderMomentTime() {
            return window.moment(`${this.$root.createdOrder.payload.time.value.date} ${this.payloadTime}`).calendar().toLowerCase();
        },

        isTakeaway() {
            return this.$root.createdOrder.payload.type === 'takeaway';
        },

        payloadTime() {
            return this.$root.createdOrder.payload.time.value.time;
        },

        payloadShop() {
            return this.$root.shops.find((shop) => shop.id === this.$root.createdOrder.payload.shop.id);
        },

        timeType() {
            return this.$root.createdOrder.payload.time.type;
        },

        finishCountMinutes() {
            return this.$root.createdOrder.finishCountMinutes;
        },

        deliveryAddress() {
            return this.$root.createdOrder.payload.delivery.formated;
        },

        shopAddress() {
            return this.$root.shops.find(shop => shop.id === $root.createdOrder.payload.shop.id).address;
        },

        cityName() {
            return this.$root.city.name;
        },

        paymentTotal() {
            return this.$root.createdOrder.payload.payment.total;
        },

        orderId() {
            return this.$root.createdOrder.orderId;
        },

        finishTime() {
            return this.$root.createdOrder.finishTime;
        },

        finishDate() {
            return this.$root.createdOrder.finishDate;
        }
    },

    watch: {
        isShow: function (isShow) {
            if (isShow === false) this.close();
        }
    }
}
</script>
